import React, { useEffect } from "react";
import { useState } from "react";
import Spinnerr from "../../Components/Spinner/Spinner";
import { Link } from "react-router-dom";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import Pagination from "../../Components/Pagination/Pagination";
import moment from "moment";
import {
  useGetNotificationAdminMutation,
  useCreatepushMutation,
} from "../../Services/api";
const SendPushNotification = ({ isNotification }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [page, setPage] = useState(1); // Initialize page state to start from 0
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [data, setdata] = useState(null);
  const dispatch = useDispatch();
  const [Description, setdescription] = useState("");
  const [title, setTitle] = useState("");
  const datasend = {
    description: Description,
    title,
  };
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 5,
  });
  const [getpushNotification, response] = useGetNotificationAdminMutation();
  const [createNotification, response2] = useCreatepushMutation();
  useEffect(() => {
    getpushNotification(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (!response.isLoading) {
      setLoading(false);
      setdata(response?.data?.data);
      // Update current page, total pages, and next page from response data
      setPage(response?.data?.data?.page);
      setTotalPages(response?.data?.data?.total_pages);
      // Assuming the API response has a field named `next_page` for the next page number
      if (response.data?.data?.notification_unread_count > 0) {
        isNotification(response.data?.data?.notification_unread_count);
      }
      setNextPage(response?.data?.data?.next_page);
      // As
    } else if (response.isError) {
      setLoading(false);
      setError(true);
    }
  }, [response]);

  const handleCreateNotification = () => {
    // console.log({ datasend });
    createNotification(datasend);
    setLoading(true);
  };
  useEffect(() => {
    if (!response2.isLoading && response2.isSuccess) {
      setModalShow(false);
      setLoading(false);
      getpushNotification(searchQuery);
      dispatch(showToast("Notification Sent", "SUCCESS_TOAST"));
    } else if (response2.isError && response2.status === "rejected") {
      // Handle error if the block API call fails
      setLoading(false);
      setError(true);
      dispatch(showToast("Some error occured", "FAIL_TOAST"));
    }
  }, [response2]);

  const setvalue = (e) => {
    setdescription(e.target.value);
  };
  const handleCloseModal = () => {
    setModalShow(false);
    setTitle("");
    setdescription("");
  };

  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        "some error occured"
      ) : (
        <>
          <div className=" buttondiv flex justify-end mx-14">
            {/*                    
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        /> */}

            <Modal
              show={modalShow}
              onHide={handleCloseModal}
              // {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <div className=" border-1 rounded-2xl bg-white p-3">
                <div className="title text-center pb-3">
                  <span className=" font-bold">Send Notification</span>
                </div>
                <input
                  className=" border-[1px] border-black rounded-lg h-10 px-2 py-2  w-full mb-2"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter title"
                />

                <textarea
                  className=" border-[1px] border-black rounded-lg h-24 px-2 py-2  w-full"
                  onChange={setvalue}
                  placeholder="Enter description"
                ></textarea>
                <div className="bt flex gap-2  justify-center  my-4">
                  <Link
                    onClick={handleCloseModal}
                    className=" border-[1px] border-black  rounded-xl text-base bg-white text-black  px-[40px] text-center py-1"
                  >
                    Close
                  </Link>
                  <Button
                    onClick={() => handleCreateNotification()}
                    className=" border-[1px] border-black rounded-xl  text-base bg-black text-white   px-[40px] text-center py-1"
                    disabled={!title || !Description}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </Modal>

            <Link
              className="text-sky-600 hover:text-sky-800  outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
              onClick={() => {
                setModalShow(true);
              }}
            >
              + Add more
            </Link>
          </div>
          {data?.notification_list.map((item) => {
            return (
              <div
                key={item._id}
                className=" border-[1px] rounded-xl mx-10 mt-4 p-3 bg-[#DFDFDF] text-white flex-col flex-wrap"
              >
                <div className="flex justify-between">
                  {item.message && (
                    <span className=" text-black text-lg font-bold">
                      {item.message}
                    </span>
                  )}
                  <p className=" font-bold text-sm text-gray-500">
                    {moment(item.createdAt).format("MM/DD/YYYY") || "-"}
                  </p>
                </div>

                <span className=" text-gray-600 text-sm font-bold">
                  {item.description.substring(0, 200)}
                </span>
              </div>
            );
          })}
        </>
      )}
      {data?.notification_list.length > 0 ? (
        <Row className="mt-4 px-2">
          <Col md={5}>
            <div className="dataTables_info">
              {`Showing ${Math.min((page - 1) * 5 + 1, data?.total)}` +
                ` to ${Math.min(page * 5, data?.total) || 0} of ${
                  data?.total || 0
                } entries`}
            </div>
          </Col>
          <Col md={7}>
            <Pagination
              total={totalPages}
              setPage={(newPage) => {
                setSearchQuery((prevQuery) => ({
                  ...prevQuery,
                  page: newPage,
                }));
              }}
              page={page} // Add 1 to page state to show correct page number
              nextPage={nextPage} // Pass the next page number to the Pagination component
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default SendPushNotification;
