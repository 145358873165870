import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import View from "../../assets/image/view.svg";
import { Row, Col } from "react-bootstrap";
import { useGetEventBookingByUserMutation } from "../../Services/api";
import Pagination from "../../Components/Pagination/Pagination";
import Spinnerr from "../../Components/Spinner/Spinner";

const EventUserBookingList = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    event_id: id,
  });
  const [page, setPage] = useState(1); // Initialize page state to start from 0
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);

  const [bookingList, response] = useGetEventBookingByUserMutation();
  useEffect(() => {
    bookingList(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  useEffect(() => {
    if (!response.isLoading && response.isSuccess) {
      setLoading(false);
      setData(response?.data?.data);
      setPage(response?.data?.data?.page);
      setTotalPages(response?.data?.data?.total_pages);
      setNextPage(response?.data?.data?.next_page);
    } else if (response.isError) {
      console.log(response?.error);
      setLoading(false);
      setError(true);
    }
  }, [response]);

  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        <h2 className="my-4">{response?.error?.data?.message}</h2>
      ) : (
        <>
          <h2 className="my-4">Booking From Users</h2>
          <div className="common-table-wrapper m-0 mt-5">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>User Name</th>
                    <th>Phone</th>
                    <th>Date of Bookings</th>
                    <th>Amount</th>
                    <th>Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.event_list.length > 0 ? (
                    data.event_list.map(
                      (
                        { _id, user_detail, createdAt, paid_amount, tax },
                        i
                      ) => (
                        <tr key={i}>
                          <td>{(page - 1) * 10 + i + 1}</td>
                          <td>{user_detail?.first_name || "-"}</td>
                          <td>{user_detail?.phone || "-"}</td>
                          <td>
                            {moment(createdAt).format("MM/DD/YYYY") || "-"}
                          </td>
                          <td>{paid_amount || "-"}</td>
                          <td>{tax || "-"}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        No Records Founds
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {data?.event_list.length > 0 ? (
              <Row className="mt-4 px-2">
                <Col md={5}>
                  <div className="dataTables_info">
                    {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                      ` to ${Math.min(page * 10, data?.total) || 0} of ${
                        data?.total || 0
                      } entries`}
                  </div>
                </Col>
                <Col md={7}>
                  <Pagination
                    total={totalPages}
                    setPage={(newPage) => {
                      setSearchQuery((prevQuery) => ({
                        ...prevQuery,
                        page: newPage,
                      }));
                    }}
                    page={page} // Add 1 to page state to show correct page number
                    nextPage={nextPage} // Pass the next page number to the Pagination component
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EventUserBookingList;
