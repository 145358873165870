import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useLazyGetUsersForSourceQuery } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";

import Dummy from "../../assets/image/user-circle.svg";
import Pagination from "../../Components/Pagination/Pagination";

const SelectedSource = ({ source, isNotification }) => {
  const [fetchUsers, { data, isLoading, isError, isSuccess }] =
    useLazyGetUsersForSourceQuery();

  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    search: source,
  });

  const [page, setPage] = useState(1); // Initialize page state to start from 0
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    setSearchQuery((prevQuery) => ({
      ...prevQuery,
      search: source,
    }));
  }, [source]);

  useEffect(() => {
    fetchUsers(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (isSuccess && data) {
      setPage(data?.data?.page || 1);
      setTotalPages(data?.data?.total_pages || 1);
      setNextPage(data?.data?.next_page || null);

      // if (data?.data?.notification_unread_count > 0) {
      //   isNotification(data?.data?.notification_unread_count);
      // }
    }
  }, [isSuccess, data]);

  return (
    <>
      {isLoading ? (
        <Spinnerr />
      ) : isError ? (
        "some error occured"
      ) : (
        <div className="common-table-wrapper m-0 source-table">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>User name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.user_list?.length > 0 ? (
                  data?.data?.user_list.map(
                    ({ _id, first_name, email, phone }, i) => (
                      <tr key={i}>
                        <td>{(page - 1) * 10 + i + 1}</td>
                        <td>
                          <span className="flex gap-2 items-center">
                            {!first_name && (
                              <img
                                src={Dummy}
                                width="15"
                                height="15"
                                className="rounded-full"
                                alt="Dummy"
                              />
                            )}
                            {first_name}
                          </span>
                        </td>
                        <td>{email || "-"}</td>
                        <td>{phone || "-"}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td className="text-center" colSpan={13}>
                      No Records Founds
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data?.data?.user_list?.length > 0 ? (
            <Row className="mt-4 px-2">
              <Col md={5}>
                <div className="dataTables_info">
                  {`Showing ${Math.min(
                    (page - 1) * 10 + 1,
                    data?.data?.total
                  )}` +
                    ` to ${Math.min(page * 10, data?.data?.total) || 0} of ${
                      data?.data?.total || 0
                    } entries`}
                </div>
              </Col>
              <Col md={7}>
                <Pagination
                  total={totalPages}
                  setPage={(newPage) => {
                    setSearchQuery((prevQuery) => ({
                      ...prevQuery,
                      page: newPage,
                    }));
                  }}
                  page={page} // Add 1 to page state to show correct page number
                  nextPage={nextPage} // Pass the next page number to the Pagination component
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default SelectedSource;
