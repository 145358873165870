import React, { useEffect, useState } from "react";
import { useGetSourcesQuery } from "../../Services/api"; // API Hook
import { Nav, Tab } from "react-bootstrap";
import SelectedSource from "./SelectedSource";

const SourceManagement = ({ isNotification }) => {
  const {
    data: sources,
    isLoading,
    error,
  } = useGetSourcesQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedSource, setSelectedSource] = useState("");

  useEffect(() => {
    if (sources?.data?.length > 0 && !selectedSource) {
      setSelectedSource(sources?.data[0]);
    }
  }, [sources, selectedSource]);

  return (
    <>
      {isLoading && <p>Loading sources...</p>}
      {error && <p>Error fetching sources</p>}

      {!isLoading && !error && sources?.data?.length > 0 && (
        <Tab.Container activeKey={selectedSource}>
          <Nav variant="pills" className="nav-pills-tabs">
            {sources.data.map((source) => (
              <Nav.Item key={source}>
                <Nav.Link
                  eventKey={source}
                  onClick={() => setSelectedSource(source)}
                >
                  {source}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="mt-4">
            {selectedSource && (
              <Tab.Pane eventKey={selectedSource}>
                <SelectedSource
                  source={selectedSource}
                  isNotification={isNotification}
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      )}
    </>
  );
};

export default SourceManagement;
