import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
  // baseUrl: "http://quytech.net:9000/admin/",
  baseUrl: "https://api.whathefootball.com/admin/",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    headers.set("Authorization", `Bearer ${token}`);
    return headers; // Return the modified headers
  },
});

console.log(baseQuery, "Base Url");
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result && result?.error?.status === 404) {
    window.location.replace(window.location.origin);
    localStorage.clear();
  }
  return result;
};
export const wtfApis = createApi({
  reducerPath: "wtfApis",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "login",
        method: "POST",
        body: data,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (data) => ({
        url: "forget_password",
        method: "POST",
        body: data,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => ({
        url: "v1/verify_otp",
        method: "POST",
        body: body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "reset_password",
        method: "POST",
        body: body,
      }),
    }),

    dashboardApi: builder.query({
      query: () => ({
        url: "v1/dashboard",
        method: "GET",
      }),
    }),
    userManagement: builder.mutation({
      query: (body) => ({
        url: "user/v1/list",
        method: "POST",
        body: body,
      }),
    }),
    getSingleUser: builder.query({
      query: (id) => ({
        url: `user/v1/detail/${id}`,
        method: "GET",
      }),
    }),
    blockUser: builder.mutation({
      query: (body) => ({
        url: "user/v1/block",
        method: "POST",
        body: body,
      }),
    }),
    eventListByUser: builder.mutation({
      query: (body) => ({
        url: "user/v1/booked_event_list",
        method: "POST",
        body: body,
      }),
    }),
    eventDetailsbyId: builder.mutation({
      query: (body) => ({
        url: "user/v1/event_detail",
        method: "POST",
        body: body,
      }),
    }),
    deleteOrganiserImage: builder.mutation({
      query: (id) => ({
        url: `organizer/v1/delete_organizer_image/${id}`,
        method: "DELETE",
      }),
    }),
    deleteEventImage: builder.mutation({
      query: (id) => ({
        url: `event/v1/delete_event_image/${id}`,
        method: "DELETE",
      }),
    }),
    organiserList: builder.mutation({
      query: (body) => ({
        url: "v1/get_organizer_list",
        method: "POST",
        body: body,
      }),
    }),
    getSingleOrganiser: builder.query({
      query: (id) => ({
        url: `v1/organizer_detail/${id}`,
        method: "GET",
      }),
    }),
    organiserApprove: builder.mutation({
      query: (body) => ({
        url: "organizer/v1/request",
        method: "POST",
        body: body,
      }),
    }),
    getEventList: builder.mutation({
      query: (body) => ({
        url: "event/v1/get_list",
        method: "POST",
        body: body,
      }),
    }),
    getEventBookingByUser: builder.mutation({
      query: (body) => ({
        url: "organizer/v1/user_booking_list",
        method: "POST",
        body: body,
      }),
    }),
    organiserReview: builder.mutation({
      query: (body) => ({
        url: "organizer/v1/review_list",
        method: "POST",
        body: body,
      }),
    }),
    eventList: builder.mutation({
      query: (body) => ({
        url: "event/v1/get_list",
        method: "POST",
        body: body,
      }),
    }),
    approveEvent: builder.mutation({
      query: (body) => ({
        url: "event/v1/approve_event",
        method: "POST",
        body: body,
      }),
    }),
    paymentList: builder.mutation({
      query: (body) => ({
        url: "payment/v1/list",
        method: "POST",
        body: body,
      }),
    }),
    markAsPaid: builder.mutation({
      query: (body) => ({
        url: "payment/v1/mark_as_paid",
        method: "POST",
        body: body,
      }),
    }),

    getCouponList: builder.mutation({
      query: (body) => ({
        url: "discount/v1/coupon_list",
        method: "POST",
        body: body,
      }),
    }),
    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `discount/v1/delete_coupon?coupon_id=${id}`,
        method: "DELETE",
      }),
    }),
    getTermsNCondition: builder.query({
      query: (body) => ({
        url: `v1/get_terms_and_condition?role_type=${body.role_type}`,
        method: "GET",
      }),
    }),
    addTermsNCondition: builder.mutation({
      query: (body) => ({
        url: `v1/add_terms_and_condition`,
        method: "POST",
        body: body,
      }),
    }),
    getAboutUs: builder.query({
      query: () => ({
        url: `v1/get_aboutus`,
        method: "GET",
      }),
    }),
    addAboutUs: builder.mutation({
      query: (body) => ({
        url: `v1/add_aboutus`,
        method: "POST",
        body: body,
      }),
    }),
    getContactUs: builder.query({
      query: () => ({
        url: `v1/get_contactus`,
        method: "GET",
      }),
    }),
    addContactUs: builder.mutation({
      query: (body) => ({
        url: `v1/add_contact_us`,
        method: "POST",
        body: body,
      }),
    }),
    getSources: builder.query({
      query: () => ({
        url: `/user/v1/source_list`,
        method: "GET",
      }),
    }),

    getUsersForSource: builder.query({
      query: (body) => ({
        url: `/user/v1/user/${body?.search}?page=${body?.page}&limit=${body?.limit}`,
        method: "GET",
      }),
    }),

    getFaqs: builder.query({
      query: (body) => ({
        url: `v1/get_faqs?role_type=${body.role_type}`,
        method: "GET",
      }),
    }),
    addFaqs: builder.mutation({
      query: (body) => ({
        url: `v1/add_faqs`,
        method: "POST",
        body: body,
      }),
    }),
    editFaqById: builder.mutation({
      query: (body) => ({
        url: `v1/edit_faqs/${body.id}`,
        method: "POST",
        body: body,
      }),
    }),
    getFaqById: builder.query({
      query: (body) => ({
        url: `v1/get_faqs/${body.id}?role_type=${body.role_type}`,
        method: "GET",
      }),
    }),
    deleteFaqs: builder.mutation({
      query: (id) => ({
        url: `v1/delete_faqs/${id}`,
        method: "DELETE",
      }),
    }),
    getPrivacyPolicy: builder.query({
      query: (body) => ({
        url: `v1/get_privacy_policy?role_type=${body.role_type}`,
        method: "GET",
      }),
    }),
    addPrivacyPolicy: builder.mutation({
      query: (body) => ({
        url: `v1/add_privacy_policy`,
        method: "POST",
        body: body,
      }),
    }),
    getCancellationPolicy: builder.query({
      query: () => ({
        url: `v1/get_cancellation_policy`,
        method: "GET",
      }),
    }),
    addCancellationPolicy: builder.mutation({
      query: (body) => ({
        url: `v1/add_cancellation_policy`,
        method: "POST",
        body: body,
      }),
    }),
    getDeactivationPolicy: builder.query({
      query: () => ({
        url: `v1/get_deactivation_policy`,
        method: "GET",
      }),
    }),
    addDeactivationPolicy: builder.mutation({
      query: (body) => ({
        url: `v1/add_deactivation_policy`,
        method: "POST",
        body: body,
      }),
    }),

    getHelpAndSupportList: builder.mutation({
      query: (body) => ({
        url: `v1/get_help_and_support_list`,
        method: "POST",
        body: body,
      }),
    }),
    getHelpAndSupportbyId: builder.query({
      query: (id) => ({
        url: `v1/get_help_and_supprot/${id}`,
        method: "GET",
      }),
    }),
    addHelpAndSupportAnswer: builder.mutation({
      query: (body) => ({
        url: `v1/add_support_answer`,
        method: "POST",
        body: body,
      }),
    }),

    getFeedbackNSuggestionList: builder.mutation({
      query: (body) => ({
        url: `v1/get_feedback_and_suggestion_list`,
        method: "POST",
        body: body,
      }),
    }),
    getFeedbackNSuggestionId: builder.query({
      query: (id) => ({
        url: `v1/get_feedback_and_suggestion/${id}`,
        method: "GET",
      }),
    }),
    addFeedbackNSuggestionAnswer: builder.mutation({
      query: (body) => ({
        url: `v1/add_feedback_answer`,
        method: "POST",
        body: body,
      }),
    }),
    addPenality: builder.mutation({
      query: (body) => ({
        url: `v1/add_cancellation_percentage`,
        method: "POST",
        body: body,
      }),
    }),
    getPenality: builder.query({
      query: () => ({
        url: `v1/get_cancellation_percentage`,
        method: "GET",
      }),
    }),
    getNotification: builder.mutation({
      query: (body) => ({
        url: `v1/notification_list`,
        method: "POST",
        body: body,
      }),
    }),
    getNotificationAdmin: builder.mutation({
      query: (body) => ({
        url: `/notification/v2/list`,
        method: "POST",
        body: body,
      }),
    }),
    deleteNotification: builder.mutation({
      query: (id) => ({
        url: `v1/delete_notification?notification_id=${id}`,
        method: "DELETE",
      }),
    }),
    getNotificationById: builder.query({
      query: (body) => ({
        url: `v1/read_notification`,
        method: "GET",
        body: body,
      }),
    }),
    getNewsList: builder.mutation({
      query: (body) => ({
        url: `/news/v2/news_list`,
        method: "POST",
        body: body,
      }),
    }),

    deleteNews: builder.mutation({
      query: (id) => ({
        url: `/news/v2/delete_news?news_id=${id}`,
        method: "DELETE",
      }),
    }),
    createpush: builder.mutation({
      query: (body) => ({
        url: `/notification/v2/send`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetNotificationAdminMutation,
  useCreatepushMutation,
  useForgetPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useDashboardApiQuery,
  useUserManagementMutation,
  useGetSingleOrganiserQuery,
  useGetSingleUserQuery,
  useBlockUserMutation,
  useEventDetailsbyIdMutation,
  useEventListByUserMutation,
  useOrganiserListMutation,
  useOrganiserApproveMutation,
  useGetEventListMutation,
  useOrganiserReviewMutation,
  useEventListMutation,
  useApproveEventMutation,
  usePaymentListMutation,
  useGetCouponListMutation,
  useDeleteCouponMutation,
  useGetEventBookingByUserMutation,
  useDeleteEventImageMutation,
  useDeleteOrganiserImageMutation,
  useMarkAsPaidMutation,

  useGetTermsNConditionQuery,
  useGetAboutUsQuery,
  useAddAboutUsMutation,
  useAddCancellationPolicyMutation,
  useAddContactUsMutation,
  useAddDeactivationPolicyMutation,
  useAddFaqsMutation,
  useAddPrivacyPolicyMutation,
  useAddTermsNConditionMutation,
  useDeleteFaqsMutation,
  useEditFaqByIdMutation,
  useGetCancellationPolicyQuery,
  useGetContactUsQuery,
  useGetDeactivationPolicyQuery,
  useGetFaqByIdQuery,
  useGetSourcesQuery,
  useLazyGetUsersForSourceQuery,
  useGetFaqsQuery,
  useGetPrivacyPolicyQuery,

  useGetHelpAndSupportListMutation,
  useGetHelpAndSupportbyIdQuery,
  useAddHelpAndSupportAnswerMutation,

  useGetFeedbackNSuggestionIdQuery,
  useGetFeedbackNSuggestionListMutation,
  useAddFeedbackNSuggestionAnswerMutation,
  useAddPenalityMutation,
  useGetPenalityQuery,

  useGetNotificationMutation,
  useDeleteNotificationMutation,

  useGetNewsListMutation,
  useDeleteNewsMutation,
} = wtfApis;
